export type formType = {
    version: string,//版本号
    update_method: string,//更新方式
    release_time: string,//发布时间
    release_status: string,//发布状态
    app_content: string,//更新内容
    min_version: string,//最低版本
    create_time: string,//上传时间
    app_url: string,//下载链接

    app_checked: number
    create_id: number
    create_name: string
    delete_time: string
    force: number
    id: number
    is_skip: number
    last_id: number
    last_name: string
    plat_form: number
    update_time: string
}

export type addFrom = {
    plat_form: PLAT_FORM,
    version: string,
    update_method: UPDATE_TYPE,
    app_content: string
    release_status: PUB,
    min_version: string,
    app_url: string,
}

export enum PLAT_FORM {
    //iosAPP
    IOS_PLAT_FORM = 5,
    //安卓APP
    AN_PLAT_FORM = 6,
    //7.9设备APP
    PC_PLAT_FORM = 2,
    //pc播放器
    PC_PLAY = 3,
    //pc内容平台
    PLAT_FORM = 4,
}

export enum UPDATE_TYPE {
    //普通更新
    BASE = 0,
    //推送更新
    PUSH = 1,
    //强制更新
    FORCE = 2,
}

export enum PUB {
    //未发布
    WAIT = 0,
    //内部发布
    OUTSIDE = 1,
    //正式发布
    ONLINE = 2,
}

export type platType = keyof typeof PLAT_FORM
