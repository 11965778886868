







































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import LsPagination from "@/components/ls-pagination.vue";
import BtnUpload from '@/components/upload/BtnUpload.vue'
import {addFrom, PUB, UPDATE_TYPE, formType, PLAT_FORM} from "@/interface";
import {apiCreateVersion, apiDelVersion, apiEditVersion, apiGetVersion} from "@/api/setting/version";

let QRCode = require('qrcode')
let canvas = document.getElementById('canvas')
@Component({
  components: {
    LsPagination, BtnUpload
  }
})
export default class VersionUpdate extends Vue {
  public addDialogVisible: boolean = false
  public editDialogVisible: boolean = false
  public pageInfo = {
    current_page: 1,
    page_size: 25,
    total: 1,
  }
  public formData: formType[] = []
  public renderFormData: formType[] = []
  public colTitle = [
    {label: '版本号', prop: "version"},
    {label: '更新方式', prop: "update_method"},
    {label: '发布时间', prop: "release_time"},
    {label: '发布状态', prop: "release_status"},
    {label: '更新内容', prop: "app_content"},
    {label: '最低版本', prop: "min_version"},
    {label: '上传时间', prop: "create_time"},
  ]
  public addVersion: any = {}
  public editVersion:  any= {}

  public async created() {
    this.getData(this.pageInfo.current_page, this.pageInfo.page_size).then(_ => {
    })
  }

  public updateTypeToString(str: UPDATE_TYPE) {
    switch (str) {
      case UPDATE_TYPE.BASE:
        return '普通更新'
      case UPDATE_TYPE.FORCE:
        return '强制更新'
      case UPDATE_TYPE.PUSH:
        return '推送更新'
    }
  }

  public StringToUpdateType(str: string) {
    switch (str) {
      case '普通更新':
        return UPDATE_TYPE.BASE
      case '强制更新':
        return UPDATE_TYPE.FORCE
      case '推送更新':
        return UPDATE_TYPE.PUSH
    }
  }

  public PUBToString(str: PUB) {
    switch (str) {
      case PUB.ONLINE:
        return '正式发布'
      case PUB.OUTSIDE:
        return '内部发布'
      case PUB.WAIT:
        return '未发布'
    }
  }

  public StringToPUB(str: string) {
    switch (str) {
      case '正式发布':
        return PUB.ONLINE
      case '内部发布':
        return PUB.OUTSIDE
      case '未发布':
        return PUB.WAIT
    }
  }

  public async downLoad(url: string) {
    console.log(url, "===downLoad")
    if (url === '') return

    function downloadHref(name:any, src:any) {
      const a = document.createElement('a');
      a.setAttribute('href', src);
      // 重命名，去掉版本号，解决safari浏览器文件格式为dms的问题
      a.setAttribute('download', name);
      document.documentElement.appendChild(a);
      a.click();
      document.documentElement.removeChild(a);
    }

    function getBlobFile(src:any) {
      return new Promise(resole => {
        const xhr = new window.XMLHttpRequest();
        xhr.open('GET', src, true);
        xhr.responseType = 'blob';
        xhr.onload = () => {
          const url = window.URL.createObjectURL(xhr.response);
          resole(url);
        };
        xhr.send();
      })
    }

    const blob = await getBlobFile(url);
    downloadHref('文件', blob)
  }

  public drawQr(url: string) {
    console.log(url, "===url")
    if (url === '') return
    QRCode.toCanvas(url, {
      errorCorrectionLevel: "L",//容错率L（低）H(高)
      margin: 1,//二维码内边距，默认为4。单位px
      height: 200,//二维码高度
      width: 200,//二维码宽度
      scal: 177,
      color: {
        dark: '#000', // 二维码背景颜色
        // light: '#000' // 二维码前景颜色
      },
      rendererOpts: {
        quality: 0.9
      }
    }).then(canvas => {
      document.getElementById('canvas').append(canvas)
    }).catch((err:any) => {
      console.log(err)
    })
  }

  public async getData(page_no: number, page_size: number) {
    let res = await apiGetVersion({plat_form: PLAT_FORM[this.$route.path.split('/').pop()], page_no, page_size})
    this.pageInfo.total = res.total
    this.pageInfo.current_page = res.page_no
    this.pageInfo.page_size = res.page_size
    this.formData = res.lists.map(item => ({
      ...item,
      update_method: this.updateTypeToString(item.update_method),
      release_status: this.PUBToString(item.release_status)
    }))
  }

  public handleSizeChangeGoods(size: number) {
    this.pageInfo.page_size = size
  }

  public pageChange() {
    this.getData(this.pageInfo.current_page, this.pageInfo.page_size)
  }

  public inputVersion(val: string, isAdd: Boolean) {
    console.log(val, "====val")
    isAdd ? this.addVersion.version = val : this.editVersion.version = val
  }

  public inputDate_content(val: string, isAdd: Boolean) {
    isAdd ? this.addVersion.app_content = val : this.editVersion.app_content = val
  }

  public changeRadio(val: UPDATE_TYPE, isAdd: Boolean) {
    isAdd ? this.addVersion.update_method = val : this.editVersion.update_method = val
  }

  public changeRelease_status(val: PUB, isAdd: Boolean) {
    isAdd ? this.addVersion.release_status = val : this.editVersion.release_status = val
  }

  public inputMini_version(val: string, isAdd: Boolean) {
    isAdd ? this.addVersion.min_version = val : this.editVersion.min_version = val
  }

  public EditVisible(item: formType) {
    this.editDialogVisible = true
    this.editVersion = {
      ...item,
      update_method: this.StringToUpdateType(item.update_method),
      release_status: this.StringToPUB(item.release_status)
    }
  }

  public async deleteData(item: formType) {
    let res = await apiDelVersion(item.id)
    this.getData(this.pageInfo.current_page, this.pageInfo.page_size).then(_ => {
    })
  }

  public handleSuccess(response: { url: string }, isAdd: Boolean) {
    isAdd ? this.addVersion.app_url = response.url : this.editVersion.app_url = response.url
  }

  public deleteUpload() {
    this.editVersion.app_url = ''
  }

  public async sendEditData() {
    this.editVersion.plat_form = PLAT_FORM[this.$route.path.split('/').pop()]
    this.editDialogVisible = false
    let res = await apiEditVersion({...this.editVersion, app_checked: '', is_skip: 0})
    if (res.code === 200) {
      this.editVersion = {
        plat_form: PLAT_FORM[this.$route.path.split('/').pop()],
        version: '',
        update_method: 0,
        app_content: '',
        release_status: 0,
        min_version: '',
        download_Link: '',
        app_url: '',
      }
      this.getData(this.pageInfo.current_page, this.pageInfo.page_size).then(_ => {
      })
    }
  }

  public async sendAddData() {
    this.addVersion.plat_form = PLAT_FORM[this.$route.path.split('/').pop()]
    this.addDialogVisible = false
    let res = await apiCreateVersion({...this.addVersion, app_checked: '', is_skip: 0})
    if (res.code === 200) {
      this.addVersion = {
        plat_form: PLAT_FORM[this.$route.path.split('/').pop()],
        version: '',
        update_method: 0,
        app_content: '',
        release_status: 0,
        min_version: '',
        app_url: '',
      }
      this.getData(this.pageInfo.current_page, this.pageInfo.page_size).then(_ => {
      })
    }
  }

  public addVersionHand() {
    this.addDialogVisible = true
  }

  @Watch('$route.path', {
    immediate: true, deep: true
  })
  public fun() {
    this.getData(this.pageInfo.current_page, this.pageInfo.page_size).then(_ => {
    })
  }

}
