import request from '@/plugins/axios'
// 版本列表
export const apiGetVersion = (params: any) =>
  request.get('/plat_form.PlatForm/getPageAndList', { params })
export const apiGetPlatForm = () =>
  request.get('/plat_form.PlatForm/getApp')
// 版本发布
export const apiCreateVersion = (Body: Object) =>
  request.post('/plat_form.PlatForm/createVersion', Body)
// 删除版本
export const apiDelVersion = (id: number): Promise<any> =>
  request.get('/plat_form.PlatForm/delVersion', { params: { id } })
// 修改版本
export const apiEditVersion = (Body: Object): Promise<any> =>
  request.post('/plat_form.PlatForm/saveDetail', Body)
// 根据ID获取版本详情
export const apiGetVersionById = (id: number): Promise<any> =>
  request.get('/plat_form.PlatForm/getDetail', { params: { id } })
